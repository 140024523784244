import React, { memo } from 'react'

interface Props {
  webp: string
  src:
    | {
        srcSet: string
        src: string
      }
    | string
  alt: string
  width?: string | number
  height?: string | number
  loading?: 'eager' | 'lazy'
  type: 'image/png' | 'image/jpeg'
}

const Image = ({ webp, src, type, alt, height, width, loading = 'eager' }: Props): JSX.Element => {
  const srcSetString = typeof src === 'object' ? src.srcSet : src
  const srcString = typeof src === 'object' ? src.src : src

  return (
    <picture>
      <source srcSet={webp} type="image/webp" />
      <source srcSet={srcSetString} type={type} />
      <img src={srcString} alt={alt} height={height} width={width} loading={loading} />
    </picture>
  )
}

export default memo(Image)
