import * as React from 'react'
import Slick from 'react-slick'
import { ArrowForwardRounded as ArrowIcon } from '@material-ui/icons'
import Typography from 'spartacus/components/Typography'
import Button from 'spartacus/components/Button'
import Colors from 'spartacus/styles/colors'
import * as S from './style'

const defaultTestimonials = [
  {
    author: 'Lauren A.',
    quote:
      'I was having suspicious logins into my Netflix and Spotify accounts. I used Spartacus to reveal which passwords had been exposed.',
  },
  {
    author: 'Rachel',
    quote: 'I signed up for the service a week or so ago. Very satisfied so far.',
  },
  {
    author: 'Denise',
    quote: 'I love my results! Thank you for keeping my data out of the data brokers!',
  },
  {
    author: 'Jesse',
    quote: 'Easy to use product and great customer service, thank you!',
  },
  {
    author: 'Jason',
    quote:
      'Great product to remove your personal information quickly off the internet. You can see the results just by searching your name a few weeks after signing-up.',
  },
  {
    author: 'Sarah',
    quote:
      'I didn’t realize how much of my personal data was being sold on the internet. Spartacus has given me a sense of privacy. Thank you!',
  },
  {
    author: 'Travis',
    quote:
      'Knowing that Spartacus is reducing my risk of identity theft by continuously removing my personal data from data brokers gives me peace of mind.',
  },
]

export interface Props {
  lightOnDark?: boolean
  testimonials?: typeof defaultTestimonials
}

const TestimonialCarousel = ({
  lightOnDark = true,
  testimonials = defaultTestimonials,
}: Props): JSX.Element => {
  const slick: React.RefObject<Slick> = React.createRef()

  function handleNextClick(): void {
    if (slick.current) {
      slick.current.slickNext()
    }
  }

  return (
    <S.Container lightOnDark={lightOnDark}>
      <Slick
        ref={slick}
        dots={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        autoplay
        autoplaySpeed={10000}
      >
        {testimonials.map(testimonial => (
          <div key={testimonial.quote}>
            <S.Slide>
              <Typography
                element="h3"
                kind="TSC - Callout"
                color={lightOnDark ? Colors.Type.Primary : Colors.Brand.Primary1}
              >
                {testimonial.author}
              </Typography>

              <Typography
                element="p"
                xs="TS3 - Strong"
                lg="TS4 - Strong"
                color={lightOnDark ? Colors.UI.White : Colors.Type.Primary}
              >
                &ldquo;{testimonial.quote}&rdquo;
              </Typography>
            </S.Slide>
          </div>
        ))}
      </Slick>
      <Button
        variant={lightOnDark ? 'text-light' : 'text'}
        width="auto"
        onClick={handleNextClick}
        endIcon={<ArrowIcon />}
      >
        NEXT
      </Button>
    </S.Container>
  )
}

export default TestimonialCarousel
