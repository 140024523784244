import Dimensions from 'spartacus/styles/dimensions'
import styled from 'styled-components'
import Colors from 'spartacus/styles/colors'

export const Banner = styled.div`
  background: ${Colors.Brand.Primary4};
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 0;

  p {
    margin: 0;
  }

  button {
    padding: 0;
    min-width: unset;
    min-height: unset;
    margin: -6px 0 0 3px;
  }
`

export const CyberText = styled.span`
  display: none;
  @media ${Dimensions.md} {
    display: inline;
  }
`
