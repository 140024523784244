import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.div<{ lightOnDark: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ lightOnDark }): string =>
    lightOnDark ? Colors.Brand.Primary1 : Colors.Brand.Primary3};
  padding: 4vh 0 2vh;

  > button {
    align-self: flex-end;
    margin-right: ${Dimensions.nColumns(1)};
  }

  @media ${Dimensions.xl} {
    padding: 8vh 0 4vh;
  }
`

export const Slide = styled.div`
  padding: 0 ${Dimensions.nColumns(2)};

  h3 {
    margin: 0 0 2vh;
  }

  @media ${Dimensions.xl} {
    padding: 0 ${Dimensions.nColumns(2)};
    display: flex;

    h3 {
      width: ${Dimensions.nColumns(6)};
      margin: 0;
    }

    p {
      width: ${Dimensions.nColumns(13)};
      margin: 0;
    }
  }
`
